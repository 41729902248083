html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

h1 {
  font-family: fraktur;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 25vh;
  text-transform: lowercase;
  font-size: 80px;
  color: gainsboro;
}
